<template>
  <div>
    <Progress v-if="!loaded" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { notificationStatus } from "@/options/notificationOptions";
import { auth } from "@/plugins/firebase";

export default {
  name: "InstagramIntegartion",
  data: () => ({
    loaded: false
  }),
  mounted() {
    this.exchangeCodeForToken();
  },
  methods: {
    ...mapActions("notifications", ["notification"]),
    ...mapActions("users", ["setSocialAccount"]),
    ...mapActions("modals", ["toggleModal"]),
    async accessTokenAction() {
      const url = `${process.env.VUE_APP_FIREBASE_FUNCTIONS_HOST}/app/socialAuthorizationCallback`;

      let token;
      await auth.currentUser
        ?.getIdToken(/* forceRefresh */ true)
        .then(function(idToken) {
          token = idToken;
        });

      return fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          code: this.$route.query.code,
          state: this.$route.query.state
        })
      });
    },
    exchangeCodeForToken() {
      this.accessTokenAction()
        .then(r => {
          return r.json();
        })
        .then(r => {
          if (r.access_token) {
            this.loaded = true;
            this.setSocialAccount({
              name: "instagram",
              accessToken: r.access_token,
              userId: r.user_id
            });

            this.toggleModal("instagram");

            this.$router.push({
              name: "Boards",
              params: {
                boardId: this.$route.query.state
              }
            });
          } else {
            throw new Error(
              "Something went wrong during authorization. Please try again."
            );
          }
        })
        .catch(err => {
          this.$router.push({
            name: "Boards",
            params: {
              instagramModal: true,
              boardId: this.$route.query.state
            }
          });
          this.notification({
            ...notificationStatus.ERROR,
            message: err.message
          });
        });
    }
  }
};
</script>
